/*
 * @Author: your name
 * @Date: 2020-12-29 16:24:10
 * @LastEditTime: 2021-06-01 17:02:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \two\util\base.js
 */
/**
 * 接口域名的管理
 */
const base = {    

    tologinB:"https://copm.luobobeidiao.cn", //扫描二维码跳转路由（通用）
    toguan:"https://share.luobobeidiao.cn", //B端官网域名

    // formal: 'https://api.luobobeidiao.cn',    
    // test: 'https://api.luobobeidiao.cn',
    // url:'https://channels.luobobeidiao.cn',  //线上访问地址（非接口地址）
    // linkurl:"https://api.luobobeidiao.cn/upload/20210120/5beec401d087ff452618986efdcdd94b.png", //分享图标 
    
    formal: 'https://api.luobobeidiao.cn', //接口地址
    test: 'https://api.luobobeidiao.cn', //接口地址
    url: 'https://channels.luobobeidiao.cn',  //线上访问地址（非接口地址）
    linkurl:"https://api.luobobeidiao.cn/upload/20210521/cd6319614a36254d01138b8efd0a1fd8.png",
}

export default base;
